import React, { useState} from 'react';
import {
    CalendulaGold, CyanList, DaybreakBlue,
    DustRedList, GeekBlue, GoldenPurple,
    LimeList, Magenta,
    PolarGreen,
    SunriseYellow,
    SunsetOrangeList,
    VolcanoList
} from "../../../Utils/data";
import {Button, Select} from "antd";
import { generate, presetDarkPalettes } from '@ant-design/colors';
import {
    red,
    volcano,
    gold,
    yellow,
    lime,
    green,
    cyan,
    blue,
    geekblue,
    purple,
    magenta,
    orange,
    grey,
} from '@ant-design/colors';


const handleRefresh = () => {
    window.location.reload();
};

const getColor = localStorage.getItem("colorPrimary") as string;

const getCalendulaGold: { label: string; value: string }[] | undefined = CalendulaGold?.filter((score) => score.value === getColor);
const getVolcanoList: { label: string; value: string }[] | undefined =VolcanoList?.filter((score) => score.value === getColor);
const getSunsetOrangeList: { label: string; value: string }[] | undefined = SunsetOrangeList?.filter((score) => score.value === getColor);
const getDustRedList: { label: string; value: string }[] | undefined = DustRedList?.filter((score) => score.value === getColor);
const getSunriseYellow: { label: string; value: string }[] | undefined = SunriseYellow?.filter((score) => score.value === getColor);
const getLimeList: { label: string; value: string }[] | undefined = LimeList?.filter((score) => score.value === getColor);
const getPolarGreen: { label: string; value: string }[] | undefined = PolarGreen?.filter((score) => score.value === getColor);
const getCyanList: { label: string; value: string }[] | undefined = CyanList?.filter((score) => score.value === getColor);
const getDaybreakBlue: { label: string; value: string }[] | undefined = DaybreakBlue?.filter((score) => score.value === getColor);
const getGeekBlue: { label: string; value: string }[] | undefined = GeekBlue?.filter((score) => score.value === getColor);
const getGoldenPurple: { label: string; value: string }[] | undefined = GoldenPurple?.filter((score) => score.value === getColor);
const getMagenta: { label: string; value: string }[] | undefined = Magenta?.filter((score) => score.value === getColor);

const allColor: Array<any> | undefined = red && volcano && gold && yellow && lime && green && cyan && blue && geekblue && purple && magenta && orange && grey?.filter((score) => score === getColor);
const getDefaultColor = allColor[0]

const BasicColor = () => {
    const [status, setStatus] = useState<string>( localStorage.getItem("colorPrimary") || "#1677ff");
    localStorage.setItem("colorPrimary", status);
    
    const colors = generate('#1890ff', {
        theme: 'default',
        backgroundColor: '#141414',
    });
    // console.log(colors, 'fdfd'); // ['#111d2c', '#112a45', '#15395b', '#164c7e', '#1765ad', '#177ddc', '#3c9ae8', '#65b7f3', '#8dcff8', '#b7e3fa']
    // console.log(presetDarkPalettes);
    return (
        <div>
            <div>
                <Select
                    defaultValue={getDustRedList[0]?.value}
                    onChange={(value, option) => {
                        setStatus(value);
                    }}
                    style={{ width: 220 }}
                    placeholder="Dust Red"
                    // status='error'
                >
                    {red.map((option) => (
                        <Select.Option
                            style={{ backgroundColor: "" }}
                            key={option}
                            value={option}
                        >
                      <span
                          style={{
                              backgroundColor: option,
                              display: "inline-block",
                              width: "12px",
                              height: "12px",
                              marginRight: "8px",
                          }}
                      />
                            {option}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div style={{ marginTop: 25 }}>
                <Select
                    defaultValue={getVolcanoList[0]?.value}
                    placeholder="Volcano"
                    onChange={(value, option) => {
                        setStatus(value);
                    }}
                    style={{ width: 220 }}
                >
                    {volcano.map((option) => (
                        <Select.Option
                            style={{ backgroundColor: "" }}
                            key={option}
                            value={option}
                        >
                      <span
                          style={{
                              backgroundColor: option,
                              display: "inline-block",
                              width: "12px",
                              height: "12px",
                              marginRight: "8px",
                          }}
                      />
                            {option}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div style={{ marginTop: 25 }}>
                <Select
                    defaultValue={getSunsetOrangeList[0]?.value}
                    placeholder="Sunset Orange"
                    onChange={(value, option) => {
                        setStatus(value);
                    }}
                    style={{ width: 220 }}
                >
                    {orange.map((option) => (
                        <Select.Option
                            style={{ backgroundColor: "" }}
                            key={option}
                            value={option}
                        >
                      <span
                          style={{
                              backgroundColor: option,
                              display: "inline-block",
                              width: "12px",
                              height: "12px",
                              marginRight: "8px",
                          }}
                      />
                            {option}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div style={{ marginTop: 25 }}>
                <Select
                    defaultValue={getCalendulaGold[0]?.value}
                    placeholder="Calendula Gold"
                    onChange={(value: string, option) => {
                        setStatus(value);
                    }}
                    style={{ width: 220 }}
                >
                    {gold.map((option) => (
                        <Select.Option
                            style={{ backgroundColor: "" }}
                            key={option}
                            value={option}
                        >
                      <span
                          style={{
                              backgroundColor: option,
                              display: "inline-block",
                              width: "12px",
                              height: "12px",
                              marginRight: "8px",
                          }}
                      />
                            {option}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div style={{ marginTop: 25 }}>
                <Select
                    placeholder="Sunrise Yellow"
                    defaultValue={getSunriseYellow[0]?.value}
                    onChange={(value, option) => {
                        setStatus(value);
                    }}
                    style={{ width: 220 }}
                >
                    {yellow.map((option) => (
                        <Select.Option key={option} value={option}>
                      <span
                          style={{
                              backgroundColor: option,
                              display: "inline-block",
                              width: "12px",
                              height: "12px",
                              marginRight: "8px",
                          }}
                      />
                            {option}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div style={{ marginTop: 25 }}>
                <Select
                    placeholder="Lime"
                    defaultValue={getLimeList[0]?.value}
                    onChange={(value, option) => {
                        setStatus(value);
                    }}
                    style={{ width: 220 }}
                >
                    {lime.map((option) => (
                        <Select.Option
                            style={{ backgroundColor: "" }}
                            key={option}
                            value={option}
                        >
                      <span
                          style={{
                              backgroundColor: option,
                              display: "inline-block",
                              width: "12px",
                              height: "12px",
                              marginRight: "8px",
                          }}
                      />
                            {option}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div style={{ marginTop: 25 }}>
                <Select
                    placeholder="Polar Green"
                    defaultValue={getPolarGreen[0]?.value}
                    onChange={(value, option) => {
                        setStatus(value);
                    }}
                    style={{ width: 220 }}
                >
                    {green.map((option) => (
                        <Select.Option
                            style={{ backgroundColor: "" }}
                            key={option}
                            value={option}
                        >
                      <span
                          style={{
                              backgroundColor: option,
                              display: "inline-block",
                              width: "12px",
                              height: "12px",
                              marginRight: "8px",
                          }}
                      />
                            {option}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div style={{ marginTop: 25 }}>
                <Select
                    placeholder="Cyan"
                    defaultValue={getCyanList[0]?.value}
                    onChange={(value, option) => {
                        setStatus(value);
                    }}
                    style={{ width: 220 }}
                >
                    {cyan.map((option) => (
                        <Select.Option
                            style={{ backgroundColor: "" }}
                            key={option}
                            value={option}
                        >
                      <span
                          style={{
                              backgroundColor: option,
                              display: "inline-block",
                              width: "12px",
                              height: "12px",
                              marginRight: "8px",
                          }}
                      />
                            {option}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div style={{ marginTop: 25 }}>
                <Select
                    placeholder="Daybreak Blue"
                    defaultValue={getDaybreakBlue[0]?.value}
                    onChange={(value, option) => {
                        setStatus(value);
                    }}
                    style={{ width: 220 }}
                >
                    {blue.map((option) => (
                        <Select.Option
                            style={{ backgroundColor: "" }}
                            key={option}
                            value={option}
                        >
                      <span
                          style={{
                              backgroundColor: option,
                              display: "inline-block",
                              width: "12px",
                              height: "12px",
                              marginRight: "8px",
                          }}
                      />
                            {option}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div style={{ marginTop: 25 }}>
                <Select
                    placeholder="Geek Blue"
                    defaultValue={getGeekBlue[0]?.value}
                    onChange={(value, option) => {
                        setStatus(value);
                    }}
                    style={{ width: 220 }}
                >
                    {geekblue.map((option) => (
                        <Select.Option
                            style={{ backgroundColor: "" }}
                            key={option}
                            value={option}
                        >
                      <span
                          style={{
                              backgroundColor: option,
                              display: "inline-block",
                              width: "12px",
                              height: "12px",
                              marginRight: "8px",
                          }}
                      />
                            {option}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div style={{ marginTop: 25 }}>
                <Select
                    placeholder="Golden Purple"
                    defaultValue={getGoldenPurple[0]?.value}
                    onChange={(value, option) => {
                        setStatus(value);
                    }}
                    style={{ width: 220 }}
                >
                    {purple.map((option) => (
                        <Select.Option
                            style={{ backgroundColor: "" }}
                            key={option}
                            value={option}
                        >
                      <span
                          style={{
                              backgroundColor: option,
                              display: "inline-block",
                              width: "12px",
                              height: "12px",
                              marginRight: "8px",
                          }}
                      />
                            {option}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div style={{ marginTop: 25 }}>
                <Select
                    placeholder="Magenta"
                    defaultValue={getMagenta[0]?.value}
                    onChange={(value, option) => {
                        setStatus(value);
                    }}
                    style={{ width: 220 }}
                >
                    {magenta.map((option) => (
                        <Select.Option
                            style={{ backgroundColor: "" }}
                            key={option}
                            value={option}
                        >
                      <span
                          style={{
                              backgroundColor: option,
                              display: "inline-block",
                              width: "12px",
                              height: "12px",
                              marginRight: "8px",
                          }}
                      />
                            {option}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div style={{ marginTop: 25 }}>
                <Select
                    placeholder="Grey"
                    defaultValue={getDefaultColor}
                    onChange={(value, option) => {
                        setStatus(value);
                    }}
                    style={{ width: 220 }}
                >
                    {grey.map((option) => (
                        <Select.Option
                            key={option}
                            value={option}
                        >
                      <span
                          style={{
                              backgroundColor: option,
                              display: "inline-block",
                              width: "12px",
                              height: "12px",
                              marginRight: "8px",
                          }}
                      />
                            {option}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div style={{ marginTop: 25 }}>
                <Button type="primary" onClick={handleRefresh}>
                    Submit
                </Button>
            </div>
        </div>
    );
};

export default BasicColor;